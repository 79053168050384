import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const key = "eigenmittel";

    const { question, answer } = TIPS_AND_TRICKS_LIST[key];

    return (
        <Layout>
            <MetaComponents title={`${question} | miracl`} description={answer} />

            <GreyHeader title={question} />

            <Article hasMarginTop hasColumns>
                <p>
                    Seit Mitte 2022 gelten in Österreich neue Regelungen beim Thema Eigenkapital. Seitdem ist man als
                    Kreditnehmer verpflichtet, ganze <b>20 % des Kaufpreises in Form von Eigenkapital</b> einzubringen.
                </p>

                <p>
                    Kostet deine Wunschimmobilie also z.B. € 250.000.- so musst du seit Juli ein Vermögen von mindestens
                    € 50.000,- vorweisen können, um einen Kredit bewilligt zu bekommen.
                </p>

                <p>
                    Zum Glück ist es aber nicht notwendig, diese Summe in Form von Bargeld zu besitzen. Denn bei der
                    Finanzierung einer Immobilie gelten grundsätzlich alle Vermögenswerte als Eigenkapital, über die du
                    frei verfügen kannst.
                </p>

                <p>Dazu zählen alle folgenden Werte: </p>
                <ul>
                    <li>Bargeld</li>
                    <li>Sparbücher und -briefe</li>
                    <li>Tages- und Festgeldkonten</li>
                    <li>Aktien und Zinspapiere</li>
                    <li>Gold und Silber</li>
                    <li>Fonds bzw. Anteile an diesen</li>
                    <li>Rückkaufswerte von Lebensversicherungen</li>
                    <li>Bausparguthaben</li>
                    <li>bebaubare Grundstücke sowie Baumaterialien</li>
                    <li>Etwaige Darlehen von Verwandten oder Arbeitgeber*innen</li>
                </ul>

                <p>
                    Vielleicht hast du also mehr Eigenkapital als du denkst? Oder in deiner Familie existieren
                    Vermögenswerte in Form von Fonds, Gold oder Bausparguthaben, auf die du zurückgreifen kannst? Falls
                    das für dich möglich ist, solltest du auf jeden Fall darüber nachdenken. Die Vorteile von mehr
                    Eigenkapital liegen schließlich auf der Hand: Weniger Kreditsumme, kürzere Laufzeit und weniger
                    Zinsen.
                </p>
            </Article>

            <TipsCarousel ignoreId={key} title={"Andere Tips"} />

            <BreadcrumbList page={key}></BreadcrumbList>

            <ArticleStructuredData
                page={key}
                heading={question}
                description={answer}
                datePublished="2022-09-12"
                dateModified="2022-09-12"
            />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
